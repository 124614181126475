/* Указываем box sizing */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Убираем внутренние отступы */
ul[class],
ol[class] {
  padding: 0;
}

/* Убираем внешние отступы */
body,
h1,
h2,
h3,
h4,
p,
ul[class],
ol[class],
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
}

/* Выставляем основные настройки по-умолчанию для body */
body {
  min-height: 100vh;
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
  background-color: #232429;
}

/* Удаляем стандартную стилизацию для всех ul и il, у которых есть атрибут class*/
ul[class],
ol[class] {
  list-style: none;
}

/* Элементы a, у которых нет класса, сбрасываем до дефолтных стилей */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* Упрощаем работу с изображениями */
img {
  max-width: 100%;
  display: block;
}

/* Указываем понятную периодичность в потоке данных у article*/
article>*+* {
  margin-top: 0rem;
}

/* Наследуем шрифты для инпутов и кнопок */
input,
button,
textarea,
select {
  font: inherit;
}

:root {
  --rsbs-backdrop-bg: rgba(0, 0, 0, 0.6);
  --rsbs-bg: #000;
  --rsbs-handle-bg: hsla(0, 0%, 0%, 0.14);
  --rsbs-max-w: auto;
  --rsbs-ml: env(safe-area-inset-left);
  --rsbs-mr: env(safe-area-inset-right);
  --rsbs-overlay-rounded: 16px;
}

.input-range__track--active {
  background: red;
}

.input-range__slider {
  appearance: none;
  background: rgb(192, 67, 67);
  border: 1px solid grey;
  border-radius: 100%;
  cursor: pointer;
  display: block;
  height: 0.8rem;
  margin-left: -0.5rem;
  margin-top: -0.65rem;
  outline: none;
  position: absolute;
  top: 52%;
  transition: transform 0.3s ease-out, box-shadow 0.3s ease-out;
  width: 0.8rem;
}

 

 
.rcw-conversation-container .rcw-close-button {
  background-color: #35cce6;
  border: 0;
  display: block;
  position: absolute;
  right: 10px;
  top: 20px;
  width: 30px;
}

.rcw-launcher { 
  -moz-animation-delay: 0;
  -moz-animation-duration: .5s;
  -moz-animation-name: slide-in;
  -moz-animation-fill-mode: forwards;
  animation-delay: 0;
  animation-duration: .5s;
  animation-name: slide-in;
  animation-fill-mode: forwards;
  align-self: flex-end;
  background-color: #35cce6;
  border: 0;
  border-radius: 50%;
  box-shadow: 0 2px 10px 1px #b5b5b5;
  height: 60px;
  margin-top: 10px;
  cursor: pointer;
  width: 60px;
  text-align: center;
  justify-content: center;
  /* text-align: center; */
  display: flex;
  align-items: center;
}