.main__products .pagination button {
    width: 100%;
    background: #F8F8F8;
    border-radius: 6px;
    padding: 15px 0; 
    font-weight: 500;
    font-size: 16px; 
    color: #333333;
    border: none;
    margin-top: 30px;
    cursor: pointer;
}

.main__products .pagination .flex {
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.main__products .pagination .flex p {
    font-weight: 500;
    font-size: 18px; 
    color: #333333;
    margin: 0 16px;
    cursor: pointer;
}