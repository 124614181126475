.catalog__filter_mobile {
    display:  none;
    padding-left: 15px;
    padding-right: 15px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: #232429;
    border-radius: 12px;
    height: 42px;
    color: #FFFFFF;
}

.header {
    display: flex;
    flex-direction: row;
    align-items: center;  
    color: #ffffff;
}

.header h5 {
    font-size: 14px;
    font-weight: 400;
    font-family: 'Inter';
    font-style: normal;
}

.header p {
    margin-left: 5px;
    background-color: red;
    font-size: 11px;
    padding: 2px 10px;
    border-radius: 9px;
    color: #ffffff;
}


 @media (max-width: 850px) {
    .catalog__filter_mobile {
        display: flex;  
    } 

    .header p {
        font-size: 12px;
        font-weight: 400;
        font-family: 'Inter';
        font-style: normal;
    }   
  
}
 