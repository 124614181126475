.category {
    width: 100%;
    background-color: #232429;
}

.items_checkbox { 
    margin-top: 2px;
    padding-left: 20px;
    padding-top: 3px;
}

.flex {
    display: flex;
    flex-direction: row;
    margin-top: 16px;
}

.flex_p {
    padding: 10px; 
    background: #f1f1f1;
    border-radius: 10px; 
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 140%;
    letter-spacing: -0.64px;
    color: #333333;
}

.flex_p2 {
    margin-left: 5px;
    padding: 10px; 
    background: #f1f1f1;
    border-radius: 10px; 
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 140%;
    letter-spacing: -0.64px;
    color: #333333;
}

.category_title {
    font-family: 'Inter'; 
    padding-bottom: 10px; 
    font-size: 16;
    font-weight: 500;
    font-style: normal;
    color: #ffffff;
} 

.catalog__filter_slider {
    margin: 32px 0;
}

@media (max-width: 1400px) {
    .medialower { 
        max-width: 1100px;
        width: 100%;
        margin: auto;
    }      
    .card { 
        height: 100%;
        width: 270px;
    }     
}

@media (max-width: 1200px) {
    .medialower { 
        max-width: 840px;
        width: 100%;
        margin: auto;
    }    
}

@media (max-width: 850px) {
    .category{
        display: none;
    }
    .medialower { 
        max-width: 550px;
        width: 100%;
        margin: auto;
    }          
}

@media (max-width: 578px) {
    .medialower { 
        max-width: 560px;
        width: 100%;
        margin: auto;
    }      
    .card {  
        height: 100%;
        width: 100%;
    }  
  
}
 
@media (max-width: 390px) { 
    .medialower {
        max-width: 390px;
        width: 100%;
        margin: auto;
    }      
     .card .prname {  
        width: 100%;
        font-size: 12px;
    } 
    .card .prprice {  
        font-size: 14px;
        font-weight: 700;
    } 
    
}

