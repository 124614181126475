.main__card {
    background: #F8F8F8;
    border-radius: 10px;
    padding: 12px;
    max-height: 450px;
    max-width: 250px;
}

.main__card .image {
    background: #FFFFFF;
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.main__card .detail {
    margin-top: 12px;
}

.main__card .detail h6 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 140%;
    display: flex;
    align-items: flex-end;
    letter-spacing: -0.635294px;
    color: #989898;
    margin: 0;
    margin-bottom: 4px;
}

.main__card .detail h3 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 140%;
    letter-spacing: -0.635294px;
    color: #333333;
    margin: 0;
}

.main__card .detail .flex_1 {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 8px;
}

.main__card .detail .flex_1 h4 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 140%;
    letter-spacing: -0.635294px;
    color: #333333;
    margin-right: 8px;
}

.main__card .detail .flex_1 h5 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 140%;
    letter-spacing: -0.635294px;
    color: #989898;
    margin: 0;
}

.main__card .detail .flex_2 {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 33px;
}

.main__card .detail .flex_2 p {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 140%;
    letter-spacing: -0.545455px;
    color: #A7A7A7;
    margin-left: 8px;
}