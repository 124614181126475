.footer {
    height: 400px;
    padding: 70px 20px 20px 20px;
    width: 100%;
    background-image: url('assets/layouts/footer_desktop.svg');
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}

.footer_between {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.p_loto_text {
    color: #6C6C6F;
    padding: 10px 0px 30px 0px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    font-family: sans-serif;
    text-align: center;
}

.link_item {
    text-decoration: none;
    color: #ffffff;
}

.p_item {
    color: #ffffff;
    padding: 10px 0px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    font-family: sans-serif;
    text-decoration: none;
}

.link_item .p_item:hover {
    cursor: pointer;
}

.hr {
    width: 100%;
    margin: auto;
    margin-top: 40px;
    border: 1px solid #6C6C6F;
}

@media (max-width: 390px) {
    .footer {
        background-image: url('assets/layouts/footer_mobile.svg');
        background-repeat: no-repeat;
        background-size: contain;
    }

    .hr {
        width: 350px;
        margin: auto;
        margin-top: 40px;
        border: 1px solid #6C6C6F;
    }
}

@media (max-width: 578px) {
    .footer {
        background-image: url('assets/layouts/footer_mobile.svg');
        background-repeat: no-repeat;
        background-size: cover;
    }
}

@media (max-width: 1400px) {
    .footer {
        background-image: url('assets/layouts/footer_mobile.svg');
        background-repeat: no-repeat;
        background-size: cover;
    }
}

@media (max-width: 1200px) {
    .footer {
        background-image: url('assets/layouts/footer_mobile.svg');
        background-repeat: no-repeat;
        background-size: cover;
    }
}

@media (max-width: 850px) {
    .footer {
        background-image: url('assets/layouts/footer_mobile.svg');
        background-repeat: no-repeat;
        background-size: cover;
    }
}