.card {
    padding: 24px;
    border-radius: 20px !important;
    background: #FFF;
    border: none !important;
    width: 100%;
    height: 95% !important;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: start;
    margin-bottom: 16px;
}

.card .card-wrapper {
    height: 100%;
}

.card .card-wrapper .img {
    width: 211px;
    height: 160px;
    flex-shrink: 0;
}

.card .card-wrapper p {
    color: #232429;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: -0.635px;
    margin: 8px 0;
}

.card .card-wrapper h5 {
    color: #232429;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: -0.635px;
    margin: 0 0 24px 0;
}

.card button {
    border-radius: 8px;
    background: #FA0100;
}

.card button:hover {
    background: #FA0100;
    color: #FFF;
}

.card button a {
    color: #FFF;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.64px;
    text-transform: lowercase;
    display: flex;
    flex-direction: row;
    text-decoration: none;
}

.card button a img {
    margin-right: 12px;
}